<template>
  <Banner title="About" />

  <section class="home-about-area section-gap">
      <div class="container">
          <div class="row align-items-center">
              <div class="col-lg-6 home-about-left">
                  <h1>{{ title }}</h1>
                  <p>
                      {{ description }}
                  </p>
                  <router-link :to="{name: 'Menu'}" class="theme-btn">view full menu</router-link>
              </div>
              <div class="col-lg-6 home-about-right">
                  <img class="img-fluid" :src="getMediaImg(additional)" alt="">
              </div>
          </div>
      </div>	
  </section>

  <section class="pb-120">
      <div class="container">
          <div class="row d-flex justify-content-center">
              <div class="menu-content pb-70 col-lg-8">
                  <div class="title text-center">
                      <h1 class="mb-10">What Kind of Services we Offer</h1>
                      <p>Who are in extremely love with eco friendly system.</p>
                  </div>
              </div>
          </div>				

          <div class="row d-flex justify-content-center">
              <div class="col-lg-3" v-for="tamani in restaurants" :key="tamani.id">
                  <div class="single-service">
                      <div class="thumb">
                          <img :src="getRestautantImg(tamani.image)" alt="">
                      </div>
                      <router-link :to="{name: 'Outlet'}">
                          <h4>{{ tamani.name }}</h4>
                      </router-link>
                      <p>
                          {{ tamani.description }}
                      </p>								
                  </div>
              </div>	
          </div>

      </div>	
  </section>
</template>

<script>
import axios from 'axios'
import Banner from '@/components/Banner.vue';
export default {
  name: 'AboutView',
  components: {
    Banner
  },
  computed: {
    restaurant(){
      return this.$store.state.restaurant
    }
  },
  data(){
    return {
        restaurants: [],
        title: null,
        description: null,
        additional: null,
    }
  },
  mounted(){
    this.getRestaurant()
    this.getAbout()
  },
  methods: {
    getRestautantImg(filename){
      return this.assets + '/images/restaurant/' + filename;
    },
    getMediaImg(filename){
      return this.assets + '/images/media/' + filename;
    },
    async getRestaurant(){
        try {
            let { data } = await axios.get('/restaurant-outlet')
            this.restaurants = data.data
        } catch(e) {
            console.log(e)
        }
    },
    async getAbout(){
        try {
            let { data } = await axios.get('/about')
            this.title = data.data.title
            this.description = data.data.content
            this.additional = data.data.additional
        } catch(e) {
            console.log(e)
        }
    },
  },
}
</script>

<style>

</style>