<template>
  <header id="header">
        <div class="header-top">
            <div class="container">
                <div class="row justify-content-center">
                        <div id="logo">
                        <a href="index.html"><img src="assets/img/logo.png" alt="" title="" /></a>
                        </div>
                </div>			  					
            </div>
        </div>
        <div class="container main-menu">
            <div class="row align-items-center justify-content-center d-flex">			
                <nav id="nav-menu-container">
                <ul class="nav-menu">
                    <li><a href="/">Home</a></li>
                    <li><router-link :to="{name: 'About'}">About</router-link></li>
                    <li><router-link :to="{name: 'Outlet'}">Outlet</router-link></li>
                    <li><router-link :to="{name: 'Menu'}">Menu</router-link></li>
                    <li><router-link :to="{name: 'Gallery'}">Gallery</router-link></li>
                    <!-- <li class="menu-has-children"><a href="">Blog</a>
                    <ul>
                        <li><a href="blog-home.html">Blog Home</a></li>
                        <li><a href="blog-single.html">Blog Single</a></li>
                    </ul>
                    </li>	
                    <li class="menu-has-children"><a href="">Pages</a>
                    <ul>
                            <li><a href="elements.html">Elements</a></li>
                            <li class="menu-has-children"><a href="">Level 2 </a>
                            <ul>
                                <li><a href="#">Item One</a></li>
                                <li><a href="#">Item Two</a></li>
                            </ul>
                            </li>					                		
                    </ul>
                    </li>					          					          		           -->
                    <li><router-link :to="{name: 'Contact'}">Contact</router-link></li>
                </ul>
                </nav>					      		  
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'NavbarVue'
}
</script>

<style>

</style>