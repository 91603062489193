import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

const app = createApp(App)

axios.defaults.baseURL = 'https://api.tamani-pos.online/api/v1';
app.config.globalProperties.assets = 'https://api.tamani-pos.online/assets';

app.use(store).use(router).mount('#app')
