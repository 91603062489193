<template>
    <footer class="footer-area">
        <div class="footer-widget-wrap">
            <div class="container" style="width: 100vw;paading: 0px 20px">
                <div class="row section-gap">
                    <div class="col-lg-8  col-md-6 col-sm-6">
                        <div class="single-footer-widget">
                            <h4>Opening Hours</h4>
                            <div class="col d-flex justify-content-between w-100">
                                <ul class="hr-list w-50">
                                    <li class="d-flex justify-content-between">
                                        <span>Sunday</span> <span>{{ sunday }}</span>
                                    </li>
                                    <li class="d-flex justify-content-between">
                                        <span>Monday</span> <span>{{ monday }}</span>
                                    </li>
                                    <li class="d-flex justify-content-between">
                                        <span>Tuesday</span> <span>{{ tuesday }}</span>
                                    </li>
                                    <li class="d-flex justify-content-between">
                                        <span>Wednesday</span> <span>{{ wednesday }}</span>
                                    </li>																	
                                </ul>
                                <ul class="hr-list w-50" style="margin-left: 100px;">
                                    <li class="d-flex justify-content-between">
                                        <span>Thursday</span> <span>{{ thursday }}</span>
                                    </li>
                                    <li class="d-flex justify-content-between">
                                        <span>Friday</span> <span>{{ friday }}</span>
                                    </li>
                                    <li class="d-flex justify-content-between">
                                        <span>Saturday</span> <span>{{ saturday }}</span>
                                    </li>		
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4  col-md-6 col-sm-6">
                        <div class="single-footer-widget">
                            <h4>{{ contact_title }}</h4>
                            <p>
                                {{ address }}
                            </p>
                            <p class="number">
                                {{ phone }}<br>
                            </p>
                            <p>
                                {{ email }}
                            </p>
                        </div>
                    </div>										
                </div>					
            </div>					
        </div>
        <div class="footer-bottom-wrap">
            <div class="container">
                <div class="row footer-bottom d-flex justify-content-between align-items-center">
                    <p class="col-lg-8 col-mdcol-sm-6 -6 footer-text m-0">
                        {{ footer }}
                    </p>

                    <ul class="col-lg-4 col-mdcol-sm-6 -6 social-icons text-right">
                        <li><a :href="splitedMedsos[0]"><i class="fa fa-facebook"></i></a></li>
                        <li><a :href="splitedMedsos[1]"><i class="fa fa-twitter"></i></a></li>
                        <li><a :href="splitedMedsos[2]"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fa fa-youtube"></i></a></li>           
                    </ul>
                </div>						
            </div>
        </div>
    </footer>
</template>

<script>
import axios from 'axios'
export default {
    name: 'FooterVue',
    data(){
        return{
            date: null,
            content: null,
            contact: [],
            contact_title: null,
            address: null,
            email: null,
            phone: null,
            time: null,
            splitedTime: [],
            operational: null,
            footer: null,
            medsos: null,
            splitedMedsos: [],
            operational: null,
            splitedOperational: [],
            sunday: null,
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
        }
    },
    mounted(){
        this.date = new Date().getFullYear()
        this.getContact()
        this.getFooter()
        this.getMedsos()
        this.getOperational()
    },
    methods:{
        async getContact(){
            try{ 
                let { data } = await axios.get('/contact')
                this.contact_title = data.data.title
                this.content = data.data.content
                this.contact = this.content.split("<br>")
                this.address = this.contact[0]
                this.email = this.contact[2]
                this.phone = this.contact[1]
                this.time = data.data.additional
                this.splitedTime = this.time.split("-")
                this.operational = 'Customer Services ' + this.splitedTime[0] + ' - ' + this.splitedTime[1]
            } catch(e) {
                console.log(e)
            }
        },
        async getFooter(){
            try{ 
                let { data } = await axios.get('/footer')
                this.footer = data.data.content
            } catch(e) {
                console.log(e)
            }
        },
        async getMedsos(){
            try{ 
                let { data } = await axios.get('/medsos')
                this.medsos = data.data.content
                this.splitedMedsos = this.medsos.split("<br>")
            } catch(e) {
                console.log(e)
            }
        },
        async getOperational(){
            try{ 
                let { data } = await axios.get('/operational')
                this.operational = data.data.content
                this.splitedOperational = this.operational.split("<br>");
                this.sunday = this.splitedOperational[0].replace('-', '  -  ');
                this.monday = this.splitedOperational[1].replace('-', '  -  ');
                this.tuesday = this.splitedOperational[2].replace('-', '  -  ');
                this.wednesday = this.splitedOperational[3].replace('-', '  -  ');
                this.thursday = this.splitedOperational[4].replace('-', '  -  ');
                this.friday = this.splitedOperational[5].replace('-', '  -  ');
                this.saturday = this.splitedOperational[6].replace('-', '  -  ');
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>

<style>

</style>