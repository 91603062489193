<template>
    <Banner title="Outlet" />

    <section class="pb-120">
        <div class="container mt-5" v-for="tamani in restaurants" :key="tamani.id" style="padding: 50px 20px;border-radius: 50px;border: 3px solid #38741c">
            <div class="row d-flex justify-content-center">
                <div class="menu-content pb-70 col-lg-8">
                    <div class="title text-center">
                        <h1 class="mb-10">{{ tamani.name }}</h1>
                        <p>Find our outlet location bellow.</p>
                    </div>
                </div>
            </div>				

            <div class="row d-flex justify-content-center" style="margin-top: -80px;">
                <div class="col-lg-3 mt-5" v-for="outlet in tamani.outlet" :key="outlet.id">
                    <div class="single-service">
                        <div class="thumb">
                            <img :src="getRestautantImg(outlet.image)" alt="">
                        </div>
                        <a href="#">
                            <h4>{{ outlet.name }}</h4>
                        </a>
                        <p style="margin-left: 20px;height: 150px;">
                            {{ outlet.address }} <br>
                            {{ outlet.description }} <br>
                            Operational : {{ outlet.time_start }} - {{ outlet.time_end }} <br><br>
                            <strong>{{ outlet.phone }}</strong>
                        </p>
                        <div class="row d-flex justify-content-center">
                            <a class="theme-btn text-white" :href="outlet.map" target="_blank">Map</a>
                        </div>							
                    </div>
                </div>	
            </div>

        </div>	
    </section>

</template>

<script>
import axios from 'axios'
import Banner from '@/components/Banner.vue'

export default {
    name: 'OutletView',
    components: {
        Banner
    },
    data(){
        return {
            restaurants: [],
        }
    },
    computed: {
        restaurant(){
            return this.$store.state.restaurant
        }
    },
    mounted(){
        this.getRestaurant()
    },
    methods: {
        getRestautantImg(filename){
            return this.assets + '/images/restaurant/' + filename;
        },
        async getRestaurant(){
            try {
                let { data } = await axios.get('/restaurant-outlet')
                this.restaurants = data.data
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>

<style>

</style>