<template>
    <Banner title="Gallery" />

    <!-- Start gallery-area Area -->
    <section class="gallery-area section-gap" id="gallery">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="menu-content pb-70 col-lg-8">
                    <div class="title text-center">
                        <h1 class="mb-10">Food and Customer Gallery</h1>
                        <p>Who are in extremely love with eco friendly system.</p>
                    </div>
                </div>
            </div>	
            
            <!-- <div class="wrap">
                <div class="box">
                    <div class="image-gallery">
                    <div class="gallery">

                        <input type="radio" checked="checked" name="select" id="img-tab-1">
                        <label for="img-tab-1" style="background-image: url(assets/img/gallery/tamani1.jpg);"></label>
                        <img src="assets/img/gallery/tamani1.jpg" alt="tamani1">

                        <input type="radio" name="select" id="img-tab-2">
                        <label for="img-tab-2" style="background-image: url(assets/img/gallery/tamani2.jpg);"></label>
                        <img src="assets/img/gallery/tamani2.jpg" alt="tamani2">

                        <input type="radio" name="select" id="img-tab-3">
                        <label for="img-tab-3" style="background-image: url(assets/img/gallery/tamani3.jpg);"></label>
                        <img src="assets/img/gallery/tamani3.jpg" alt="tamani3">
                        
                        <input type="radio" name="select" id="img-tab-4">
                        <label for="img-tab-4" style="background-image: url(assets/img/gallery/tamani4.jpg);"></label>
                        <img src="assets/img/gallery/tamani4.jpg" alt="tamani4">
                        
                        <input type="radio" name="select" id="img-tab-5">
                        <label for="img-tab-5" style="background-image: url(assets/img/gallery/tamani5.jpg);"></label>
                        <img src="assets/img/gallery/tamani5.jpg" alt="tamani5">
                        
                        <input type="radio" name="select" id="img-tab-6">
                        <label for="img-tab-6" style="background-image: url(assets/img/gallery/tamani6.jpg);"></label>
                        <img src="assets/img/gallery/tamani6.jpg" alt="tamani6">
                        
                        <input type="radio" name="select" id="img-tab-7">
                        <label for="img-tab-7" style="background-image: url(assets/img/gallery/tamani7.jpg);"></label>
                        <img src="assets/img/gallery/tamani7.jpg" alt="tamani7">
                        
                        <input type="radio" name="select" id="img-tab-8">
                        <label for="img-tab-8" style="background-image: url(assets/img/gallery/tamani8.jpg);"></label>
                        <img src="assets/img/gallery/tamani8.jpg" alt="tamani8">
                        
                    </div>
                    </div>
                </div>
            </div> -->

            <div class="row w-70 d-flex">
                <div class="gallery-wrapper" v-for="image in media" :key="image.id" @click="thumbnail(image.name)">
                    <img style="width: 100%" :src="getGalleryImg(image.name)" alt="">
                </div>
            </div>

            <div class="w-100 h-100 thumbnail-wrapper" v-show="selectedImage" @click="selectedImage = null">
                <div class="thumbnail-img">
                    <img :src="getGalleryImg(selectedImage)" alt="">
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import Banner from '@/components/Banner.vue';

export default {
    name: 'GalleryView',
    components: {
        Banner
    },
    data(){
        return {
            media: [],
            selectedImage: null,
        }
    },
    mounted(){
        this.getGallery()
    },
    methods:{
        async getGallery(){
            try{
                let { data } = await axios.get('/gallery')
                this.media = data.data
            } catch(e) {
                console.log(e)
            }
        },  
        getGalleryImg(filename){
            return this.assets + '/images/gallery/' + filename;
        },
        thumbnail(image){
            this.selectedImage = image;
        }
    }
}
</script>

<style>
.gallery-wrapper{
    width: 350px;
    margin: 20px 10px;
    border-radius: 10px;
    overflow: hidden;
    outline: 1px solid #000;
    outline-offset: 3px;
    cursor: pointer;
}

.gallery-wrapper:hover{
    animation: slideUp 1s ease infinite;
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-25px);
    }

    100% {
        transform: translateY(0);
    }
}

.thumbnail-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #000000d3;
}

.thumbnail-img{
    position: fixed;
    top:20%;
    left:25%;
    animation: fadeIn 1s;
    outline: 1px solid #fff;
    outline-offset: 5px;
}

@keyframes fadeIn {
    0% {
        transform: scale(0);
        opacity: 0.0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>