<template>
    <Banner title="Contact" />

    <!-- Start contact-page Area -->
    <section class="contact-page-area section-gap">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 d-flex flex-column address-wrap">
                    <div class="single-contact-address d-flex flex-row">
                        <div class="icon">
                            <span class="lnr lnr-home"></span>
                        </div>
                        <div class="contact-details">
                            <p>
                                {{ address }}
                            </p>
                        </div>
                    </div>
                    <div class="single-contact-address d-flex flex-row">
                        <div class="icon">
                            <span class="lnr lnr-phone-handset"></span>
                        </div>
                        <div class="contact-details">
                            <h5>{{ phone }}</h5>
                            <p>{{ operational }}</p>
                        </div>
                    </div>
                    <div class="single-contact-address d-flex flex-row">
                        <div class="icon">
                            <span class="lnr lnr-envelope"></span>
                        </div>
                        <div class="contact-details">
                            <h5>{{ email }}</h5>
                            <p>Send us your query anytime!</p>
                        </div>
                    </div>														
                </div>
                <div class="col-lg-8">
                    
                </div>
            </div>
        </div>	
    </section>
</template>

<script>
import Banner from '@/components/Banner.vue';
import axios from 'axios'

export default {
    name: 'ContactView',
    components: {
        Banner
    },
    data(){
        return {
            content: null,
            contact: [],
            address: null,
            email: null,
            phone: null,

            time: null,
            splitedTime: [],
            operational: null,
        }
    },
    mounted(){
        this.getContact()
    },
    methods: {
        async getContact(){
            try{ 
                let { data } = await axios.get('/contact')
                this.content = data.data.content
                this.contact = this.content.split("<br>")
                this.address = this.contact[0]
                this.email = this.contact[2]
                this.phone = this.contact[1]
                this.time = data.data.additional
                this.splitedTime = this.time.split("-")
                this.operational = 'Customer Services ' + this.splitedTime[0] + ' - ' + this.splitedTime[1]
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>

<style>

</style>