<template>
<section class="banner-area">		
    <div class="container">
        <div class="row fullscreen align-items-center justify-content-between">
            <div class="col-lg-12 banner-content">
                <div>
                    <h6 class="text-white">{{ title }}</h6>
                    <h1 class="text-theme">{{ store_name }}</h1>
                    <p class="text-white">
                       {{ description }}
                    </p>
                    <router-link :to="{name: 'Menu'}" class="theme-btn text-uppercase">Check Our Menu</router-link>
                </div>
                
                <iframe class="embeed-front" 
                        :src="link" title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>
                </iframe>
            </div>
        </div>
    </div>					
</section>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HomeView',
  data(){
    return {
        welcome: null,
        splitedWelcome: [],
        title: null,
        store_name: null,
        description: null,
        link: null,
    }
  },
  mounted(){
    this.getWelcome()
  },
  methods: {
    async getWelcome(){
        try {
            let { data } = await axios.get('/welcome-text')
            this.welcome = data.data.content
            this.splitedWelcome = this.welcome.split("<br>")
            this.title = data.data.title
            this.store_name = this.splitedWelcome[0]
            this.description = this.splitedWelcome[1]
            this.link = 'https://www.youtube.com/embed/' + data.data.additional;
        } catch(e) {
            console.log(e)
        }
    },
  }
}
</script>

<style>

</style>