<template>
    <section class="about-banner relative">
        <div class="overlay overlay-bg"></div>
        <div class="container">				
            <div class="row d-flex align-items-center justify-content-center">
                <div class="about-content col-lg-12">
                    <h1 class="text-white">
                        {{ title }}				
                    </h1>	
                    <p class="text-white link-nav"><a href="/">Home </a>  <span class="lnr lnr-arrow-right"></span>  <router-link :to="{name: title}">{{ title }}</router-link></p>
                </div>	
            </div>
        </div>
    </section>	
</template>

<script>
export default {
    name: 'BannerVue',
    props: ['title']
}
</script>

<style>

</style>