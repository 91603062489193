import { createStore } from 'vuex'

export default createStore({
  state: {
    kafe_name: 'Tamani Kafe',
    kafe_description: '',
    kafe_image: 'tamani_kafe.jpg',
    kafe_category: [
        {
            id: 0,
            kode: 'C10', 
            name: 'Appetizer', 
            image: 'appetizer.png',
            food: [
              {id: 1, name: 'WAGYU ENOKI TERIYAKI', description: '', image: 'wagyu-enoki-teriyaki-300x250.jpg', price: 53000},
              {id: 2, name: 'STUFFED THAI PRAWN BEANCURD', description: '', image: 'crispy-stuffed-bean-curd-300x250.jpg', price: 46000},
              {id: 3, name: 'CRISPY SALTED EGG TOFU', description: '', image: 'tahu-telur-asin-300x250.jpg', price: 48000},
              {id: 4, name: 'TRUFFLE FRIES', description: '', image: 'truffle-fries-300x250.jpg', price: 61000},
              
            ]
        },
        {
            id: 1,
            kode: 'C20', 
            name: 'Soups and Salads', 
            image: 'soups_and_salads.png',
            food: [
              {id: 1, name: 'CHEF SALAD', description: '', image: 'chef-salad-300x250.jpg', price: 89000},
              {id: 2, name: 'CAESAR SALAD W/ GRILLED CHICKEN', description: '', image: 'caesar-salad-grilled-chicken-300x250.jpg', price: 89000},
              {id: 3, name: 'WAKAME SALAD W/ ROASTED SESAME DRESSING', description: '', image: 'wakame-salad-roasted-sesame-dressing-300x250.jpg', price: 69000},
              {id: 4, name: 'CREAMY MUSHROOM SOUP', description: '', image: 'creamy-mushroom-soup-300x250.jpg', price: 36000},
            ]
        },
        {
            id: 2,
            kode: 'C30', 
            name: 'Burger', 
            image: 'burger.png',
            food: [
              {
                id: 1, 
                name: 'SWISS MUSHROOM BURGER', 
                description: '', 
                image: 'swiss-mushroom-burger-no-tomato-kafe-300x250.jpg', 
                price: 89000,
                option: [
                  {id: 1, name: 'SINGLE', price: 82000},
                  {id: 2, name: 'DOUBLE', price: 104000},
                ]
              },
              {
                id: 2, 
                name: 'SIMPLY CHEESE BURGER', 
                description: '', 
                image: 'simply-cheese-burger-no-tomato-kafe-300x250.jpg', 
                price: 89000,
                option: [
                  {id: 1, name: 'SINGLE', price: 78000},
                  {id: 2, name: 'DOUBLE', price: 100000},
                ]
              },
              {id: 3, name: 'CHICKEN BURGER', description: '', image: 'chicken-burger-300x250.jpg', price: 69000},
            ]
        },
        {
            id: 3,
            kode: 'C40', 
            name: 'Western Favourites', 
            image: 'western_favourites.png',
            food: [
              
            ]
        },
        {
            id: 4,
            kode: 'C50', 
            name: 'Taste OF Asia', 
            image: 'taste_of_asia.png',
            food: [

            ]
        },
        {   
            id: 5,
            kode: 'C60', 
            name: 'Drinks and Dessert', 
            image: 'drinks_and_dessert.png',
            food: [

            ]
        },
    ],
    express_name: 'Tamani Express',
    express_description: '',
    express_image: 'tamani_kafe.jpg',
    express_category: [
        {
            id: 0,
            kode: 'C10', 
            name: 'Food', 
            image: 'food.png',
            food: [
              {id: 1, name: 'RIB EYE ANGUS STEAK (150gr)', description: '', image: 'rib-eye-angus-express-300x250.jpg', price: 139090},
              {
                id: 2, 
                name: 'AUSTRALIAN ', 
                description: '', 
                image: 'australian-sirloin-steak-300x250.jpg', 
                price: 46000,
                option: [
                  {id: 1, name: 'PRIME SIRLOIN', price: 136363},
                  {id: 2, name: 'SIRLOIN', price: 114545}
                ]
              },
              {id: 3, name: 'AUSTRALIAN PRIME TENDERLOIN STEAK', description: '', image: 'australian-prime-tenderloin-300x250.jpg', price: 136363},
              {
                id: 4, 
                name: 'GRILLED SALMON', 
                description: '', 
                image: 'grilled-fresh-salmon-mentai-mayo-300x250.jpg', 
                price: 120909,
                option: [
                  {id: 1, name: 'GRILLED FRESH SALMON', price: 120909},
                  {id: 2, name: 'WITH MENTAI MAYO', price: 124545},
                ]
              },
              {
                id: 5, 
                name: 'PESTO', 
                description: '', 
                image: 'salmon-pesto-mentai-mayo-300x250.jpg', 
                price: 120909,
                option: [
                  {id: 1, name: 'SALMON PESTO', price: 124545},
                  {id: 2, name: 'WITH MENTAI MAYO', price: 136363},
                ]
              },
            ]
        },
        {
            id: 1,
            kode: 'C20', 
            name: 'Beverage', 
            image: 'beverage.png',
            food: [
              {id: 1, name: 'CHEF SALAD', description: '', image: 'chef-salad-300x250.jpg', price: 89000},
              {id: 2, name: 'CAESAR SALAD W/ GRILLED CHICKEN', description: '', image: 'caesar-salad-grilled-chicken-300x250.jpg', price: 89000},
              {id: 3, name: 'WAKAME SALAD W/ ROASTED SESAME DRESSING', description: '', image: 'wakame-salad-roasted-sesame-dressing-300x250.jpg', price: 69000},
              {id: 4, name: 'CREAMY MUSHROOM SOUP', description: '', image: 'creamy-mushroom-soup-300x250.jpg', price: 36000},
            ]
        },
    ],
    restaurant: [
      {
        id: 1, 
        name: 'Tamani Kafe', 
        description: '', 
        color: 'linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%)',
        image: 'tamani_kafe.jpg',
        outlet: [
          {
            id: 1, 
            name: 'Tamani Tomang Raya', 
            address:'Jl. Tomang Raya No. 15 A, Tomang, Jakarta Barat 11440.', 
            operational: 'Operational: 11 AM – 10 PM',
            telp: '(021) 5694-3269',
            image: 'tamani_tomang.jpg', 
            map: 'https://www.google.com/maps/place/Tamani+Cafe/@-6.1779695,106.7981576,15z/data=!4m2!3m1!1s0x0:0x1a86d025cc4ecc54?sa=X&ved=2ahUKEwjl3vzro87_AhVHwzgGHX9tDzIQ_BJ6BAhgEAg&hl=id'
          },
          {
            id: 2, 
            name: 'Tamani Kota Kasablanka', 
            image: 'tamani_kota_kasablanka.jpg', 
            address:'Jl. Casablanca Kav 88, Menteng Dalam, Kec. Tebet, Jakarta Selatan 12870.', 
            operational: 'Operational: 9 AM – 9.30 PM',
            telp: '(021) 2946 4997',
            map: 'https://www.google.com/maps/place/Tamani+Kota+Kasablanka/@-6.2234308,106.8421236,17z/data=!3m2!4b1!5s0x2e69f38cc13b8945:0x3333a5700e11de80!4m6!3m5!1s0x2e69f38cc0efc317:0xcfde5fcb4472fd0e!8m2!3d-6.2234308!4d106.8421236!16s%2Fg%2F1ptvtfkx0?hl=id&entry=ttu'
          },
          {
            id: 3, 
            name: 'Tamani Hayam Wuruk', 
            image: 'tamani_hayam_wuruk.jpg', 
            address:'Jalan Hayam Wuruk No.96A, Kec. Taman Sari, Jakarta Barat 11160.', 
            operational: 'Operational: 11 AM – 8 PM',
            telp: '(021) 3190 1321',
            map: 'https://www.google.com/maps/place/Tamani+Kafe/@-6.1547257,106.818219,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f5e0243698d5:0x565c10c7b578e90d!8m2!3d-6.1547257!4d106.818219!16s%2Fg%2F1tdrbh8t?hl=id&entry=ttu'
          },
          {
            id: 4, 
            name: 'Tamani Citra Garden 6', 
            image: 'tamani_citra_garden6.jpg', 
            address:'Jl. Citra Garden City 6 No.2C, Kec. Kalideres, Jakarta Barat 11820.', 
            operational: 'Operational: 11 AM – 10 PM',
            telp: '(021) 2230 2653',
            map: 'https://www.google.com/maps/place/Tamani+Cafe+Citra+Garden+6/@-6.1271493,106.7136358,17z/data=!3m1!4b1!4m6!3m5!1s0x2e6a02b046b40a25:0x870430ac0495c824!8m2!3d-6.1271493!4d106.7136358!16s%2Fg%2F11cn0p064s?hl=id&entry=ttu'
          },
          {
            id: 5, 
            name: 'Tamani Salemba', 
            image: 'tamani_salemba.jpg', 
            address:'Jl. Salemba Raya No.57-59, Kec. Senen, Jakarta Pusat 10440.', 
            operational: 'Operational: 11 AM – 8 PM',
            telp: '(021) 3190 1321',
            map: 'https://www.google.com/maps/place/Tamani+-+Salemba/@-6.1974487,106.8524188,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f443aa1f66b3:0x1cc18448f6c20ad4!8m2!3d-6.1974487!4d106.8524188!16s%2Fg%2F1tfd9kzj?hl=id&entry=ttu'
          },
        ]
      },
      {
        id: 2, 
        name: 'Tamani Express', 
        description: '', 
        image: 'tamani_express.jpg',
        outlet: [
          {
            id: 1, 
            name: 'Tamani Express Pondok Indah Mall 2', 
            image: 'tamani_pondok_indah_mall2.jpg', 
            address:'Jl. Metro Pondok Indah No.102, Kec. Kby. Lama, Jakarta Selatan 12310.', 
            operational: 'Operational: 10 AM – 9.30 PM',
            telp: '(021) 7592-0627',
            map: 'https://www.google.com/maps/place/Tamani+Express+-+Pondok+Indah+Mall+2/@-6.2663173,106.7832419,15z/data=!4m2!3m1!1s0x0:0x75680169f7052866?sa=X&ved=2ahUKEwjzqvCyns7_AhUHxDgGHfmwADUQ_BJ6BAhhEAg&hl=id'
          },
          {
            id: 2, 
            name: 'Tamani Express Plaza Senayan', 
            image: 'tamani_plaza_senayan.jpg', 
            address:'Jl. Asia Afrika No. 8 , Plaza Senayan Lt. 3, Jakarta Pusat 10270.', 
            operational: 'Operational: 10 AM – 9.30 PM',
            telp: '(021) 572 5219',
            map: 'https://www.google.com/maps/place/Tamani+Express/@-6.225032,106.799125,15z/data=!4m2!3m1!1s0x0:0x13bcb512a18c36b7?sa=X&ved=2ahUKEwiLpsLhns7_AhUYxTgGHVzeC9gQ_BJ6BAheEAg&hl=id'
          }
        ]
      },
      {
        id: 3, 
        name: 'Tamani Kids', 
        description: '', 
        image: 'tamani_kids.jpg',
        outlet: [
          {
            id: 1, 
            name: 'Tamanni Kemang Square', 
            image: 'tamani_kemang.jpg', 
            address:'Jl. Kemang Raya No.5 A, Kec. Mampang Prpt. Jakarta Selatan 12730.', 
            operational: 'Operational: 11 AM – 8 PM',
            telp: '(021) 719-8077',
            map: 'https://www.google.com/maps/place/Tamani+Kids+Cafe/@-6.2554487,106.8075903,17z/data=!4m6!3m5!1s0x2e69f16c368dd889:0xbbdf3488cd959b7!8m2!3d-6.2554377!4d106.8098721!16s%2Fg%2F1hc14pw50?hl=id&entry=ttu'
          },
          {
            id: 2, 
            name: 'Tamani Cinere', 
            image: 'tamani_cinere.jpg', 
            address:'Jl. Cinere Raya No.12A, Kec. Cinere, Kota Depok 16514.', 
            operational: 'Operational: 11 AM – 8 PM',
            telp: '(021) 7533 762',
            map: 'https://www.google.com/maps/place/Tamani+Kafe/@-6.3273129,106.7852924,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69ee45a453e8d3:0x228cc1e0330a3c35!8m2!3d-6.3273129!4d106.7852924!16s%2Fg%2F11g_f_ybw?hl=id&entry=ttu'
          },
        ]
      },
      {
        id: 4, 
        name: 'Tamani Plus', 
        description: '', 
        image: 'tamani_plus.jpg',
        outlet: [
          {
            id: 1, 
            name: 'Tamani Mall Artha Gading', 
            image: 'tamani_mall_artha_gading.jpg', 
            address:'Jl. Boulevard Artha Gading No.1, Kelapa Gading, Jakarta Utara 14240.', 
            operational: 'Operational: 10 AM – 10 PM',
            telp: '(021) 4586-4086',
            map: 'https://www.google.com/maps/place/Tamani+Kafe/@-6.1456589,106.889539,17z/data=!3m1!5s0x2e69f5431cec6cef:0x9b6cffb20890530!4m6!3m5!1s0x2e69f5431f0c8cb5:0xb2702dc3e0406a80!8m2!3d-6.1456642!4d106.8917331!16s%2Fg%2F1hc667jt4?hl=id&entry=ttu'
          },
          {
            id: 2, 
            name: 'Tamani WTC Sudirman', 
            image: 'tamani_wtc.jpg', 
            address:'RT.8/RW.3, RT.8/RW.3, Kuningan, Karet, Setiabudi, Jakarta 12920.', 
            operational: 'Operational: 9 AM – 9 PM (Weekday)',
            telp: '(021) 252-5105',
            map: 'https://www.google.com/maps/place/Tamani+plus+wtc+6/@-6.2148618,106.8193901,17z/data=!4m6!3m5!1s0x2e69f53ffb7fa943:0x1e85bc40c748f85b!8m2!3d-6.2148618!4d106.8193901!16s%2Fg%2F11h5wsv0xz?hl=id&entry=ttu'
          },
        ]
      },
    ],
  },
  getters: {
  },
  mutations: {
    saveKafeName(state, newName){
      state.kafe_name = newName;
    },
    saveKafeDesc(state, newDescription){
      state.kafe_description = newDescription;
    },
    saveKafeImg(state, newImage){
      state.kafe_image = newImage
    },
    saveKafeCategory(state, newCategory){
      state.kafe_category = newCategory;
    }
  },
  actions: {
  },
  modules: {
  }
})
